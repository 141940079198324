import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { FORM_ID } from 'src/models/FormModel';
import { getGatewayListAction } from 'src/redux/actions/gatewayAction';
import { ProcessingType } from 'src/models/GatewayModel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '200px',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  form: FORM_ID,
  name: string,
  onChange: (id:number) => void,
  value?: number,
  type?: ProcessingType,
  margin?: 'dense' | 'normal' | undefined,
  noMarginTop?: boolean
  disabled?: boolean,
}

interface DataItem {
  id: number,
  label?: string,
}

const GatewaySelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    form, name, onChange, value, type, margin, noMarginTop, disabled,
  } = props;

  const data = useSelector((state: Store) => state.gateways.filter(
    (gateway) => (!!type ? (gateway.processing_type === type) : gateway),
  ));

  const getLabel = () => {
    if (type === ProcessingType.cc) return 'Gateway for credit card';
    return 'Gateway for EFT/ bank transfer';
  };

  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  useEffect(() => {
    dispatch(getGatewayListAction());
  }, [type]);

  const getItems = () => {
    const items:DataItem[] = [{ id: 0 }];
    if (!data || !data.length) {
      return items;
    }
    data.sort(
      (a, b) => a.gateway_name.localeCompare(b.gateway_name),
    ).forEach((item: any) => {
      items.push({
        id: item.id,
        label: item.gateway_name,
      } as DataItem);
    });
    return items;
  };

  const onValueChange = (item: DataItem) => {
    if (!item) {
      onChange(0);
    }
    onChange(item.id as number);
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
    } as DataItem;
    if (!data || !data.length) {
      return result;
    }
    data.forEach((item: any) => {
      if (item.id === initialValue) {
        result = {
          id: item.id,
          label: item.gateway_name,
        } as DataItem;
      }
    });
    return result;
  };

  const displayLabel = (option:DataItem) => {
    if (option.label) {
      return (
        <span>{ option.label }</span>
      );
    }
    return (<></>);
  };

  const getOptionLabel = (option: DataItem) => option.label || '';

  if (!type) return (<></>);
  return (
    <Autocomplete
      disabled={disabled}
      className={`${noMarginTop ? '' : classes.marginTop} ${classes.formControl}`}
      value={getValue(value)}
      onChange={(e, item) => onValueChange(item as DataItem)}
      options={getItems() || []}
      getOptionLabel={(option: DataItem) => getOptionLabel(option)}
      getOptionSelected={(option, item) => option.id === item.id}
      renderOption={(option: DataItem) => displayLabel(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={getLabel()}
          placeholder={getLabel()}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].join(',')}
          margin={margin || 'normal'} />
      )} />
  );
};

export default GatewaySelector;

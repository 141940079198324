import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { isEmpty, keyBy, sortBy } from 'lodash';
import { getOrganisationBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import { getGatewayListAction } from 'src/redux/actions/gatewayAction';
import { BankAccountModel, BankAccountPayload } from 'src/models/BankAccountModel';
import EditBankAccountForm from 'src/components/Forms/EditBankAccountForm';
import { Pagination } from '@material-ui/lab';
import Item from 'src/components/Elements/Item';
import EditButton from 'src/components/UI/EditButton';
import DeleteButton from 'src/components/Elements/DeleteButton';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import Scroller from 'src/components/UI/Scroller';
import { getCurrenciesAction } from 'src/redux/actions/dataActions';
import AddBankAccountForm from 'src/components/Forms/AddBankAccountForm';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageBankAccounts = () => {
  const dispatch = useAppDispatch();

  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const bankAccounts = useSelector(
    (state: Store) => state.organisationBankAccounts[organisationId] || [] as any[],
  );
  const gateways = useSelector(
    (state: Store) => state.gateways ?? {},
  );

  const canDeleteBankAccount = (id: number) => {
    const gatewayList = gateways;
    if (isEmpty(gatewayList)) {
      return true;
    }
    return (!(keyBy(gatewayList, 'bank_account_id')[id]));
  };

  useEffect(() => {
    dispatch(getCurrenciesAction());
    dispatch(getOrganisationBankAccountAction());
    dispatch(getGatewayListAction());
  }, []);

  const lang = useSelector((state: Store) => state.language.language);

  const [currentAccount, setCurrentAccount] = useState({} as BankAccountPayload);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, handleSearch] = useState('');
  const [page, setPage] = useState(1);

  const handleDelete = (account: BankAccountPayload) => {
    dispatch(openDialog(
      {
        dialog: DIALOGS.deleteBankAccount,
        payload: { accountId: account.id, contactId: undefined },
      },
    ));
  };

  const handleEdit = (account: BankAccountPayload) => {
    setCurrentAccount(account);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentAccount({} as BankAccountPayload);
    setCurrentState(STATE.add);
  };

  const handlePageChange = (e:any, newPage:number) => {
    setPage(newPage);
  };

  const displayBankAccount =
    (account: BankAccountPayload) => `
      ${account.bank}-${account.branch}-${account.account} (${account.bank_name})
    `;

  const sortBankAccounts = () => sortBy(
    bankAccounts, (account: BankAccountModel) => account.bank_name,
  );

  const filterBankAccounts = (sortedAccounts: BankAccountModel[]) => sortedAccounts
    .filter((account: BankAccountModel) => {
      if (!currentFilter) return true;
      const bankName = account.bank_name || '';
      const bankNameLowercase = bankName.toLowerCase();
      const currentFilterLowercase = currentFilter.toLowerCase();
      return bankNameLowercase.includes(currentFilterLowercase);
    });

  const getItems = (filteredBankAccounts: BankAccountModel[]) => filteredBankAccounts
    .map((account: BankAccountModel) => (
      <Item key={`account-${account.id}`}>
        <span>{displayBankAccount(account)}</span>
        <EditButton onClick={() => handleEdit(account)} title={t(lang, 'menus.edit_account')} />
        <DeleteButton
          onClick={() => handleDelete(account)}
          title={t(lang, 'menus.delete_account')}
          disabled={!canDeleteBankAccount(account.id)} />
      </Item>
    ));

  const ListItems = () => {
    const sortedBankAccounts = sortBankAccounts();
    const filteredBankAccounts = filterBankAccounts(sortedBankAccounts);
    if (filteredBankAccounts.length <= 15) {
      return (<div>{getItems(filteredBankAccounts)}</div>);
    }

    const count = Math.ceil(filteredBankAccounts.length / 15);
    const start = (page - 1) * 15;
    const paginatedAccounts = filteredBankAccounts.slice(start, start + 15);

    return (
      <>
        <Pagination page={page} count={count} onChange={handlePageChange} size="small" />
        <div style={{ marginBottom: '8px', marginTop: '8px' }}>
          {getItems(paginatedAccounts)}
        </div>
      </>
    );
  };

  const EditForm = () => (
    <EditBankAccountForm
      account={currentAccount}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const AddForm = () => (
    <AddBankAccountForm onClose={() => setCurrentState(STATE.list)} />
  );

  return (
    <>
      { currentState === STATE.list && (
        <ItemSearchBox
          onAdd={handleAdd}
          testId="manage-add-bank-account"
          title={t(lang, 'menus.add_bank_account')}
          onChange={handleSearch} />
      )}
      <Scroller height="597px">
        { currentState === STATE.list && <ListItems /> }
        { currentState === STATE.add && <AddForm /> }
        { currentState === STATE.edit && <EditForm /> }
      </Scroller>
    </>
  );
};

export default ManageBankAccounts;

import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { MouseEvent, useState } from 'react';
import { CreditCardPayload } from 'src/models/CreditCardModel';
import CreditCardValidator from 'src/validations/CreditCardValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import { postContactCreditCardAction } from 'src/redux/actions/paymentInfoAction';
import { getCardType } from 'src/lib/PaymentHelper';
import Header from 'src/components/UI/Header';
import Title from 'src/components/UI/Title';
import TopRow from 'src/components/UI/TopRow';
import BottomRow from 'src/components/UI/BottomRow';
import CloseButton from 'src/components/UI/CloseButton';

const AddCreditCardForm = () => {
  const dispatch = useAppDispatch();

  const user = useSelector((state: Store) => state.user);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const contactId =
  useSelector((state: Store) => state.modals[MODALS.addCreditCard].payload.contact_id);
  const [state, setState] = useState({ contact_id: contactId } as CreditCardPayload);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.addCreditCard }));
  };

  const getMonth = (value:string) => {
    if (Number(value) === 0) {
      return value;
    }
    if (Number(value) < 10 && value.length < 2) {
      return `0${value}`;
    }
    return value;
  };

  const onChange = (value: string | undefined, field: string) => {
    if (!!value) {
      switch (field) {
        case 'card_number':
          if (value.length > 16) return;
          break;
        case 'expiration_month':
          if (value.length > 2) return;
          if (Number(value) > 12) return;
          setState({
            ...state,
            expiration_month: getMonth(value),
          });
          break;
        case 'expiration_year':
          if (value.length > 2) return;
          break;
        case 'cvv':
          if (value.length > 4) return;
          break;
        default:
      }
    }

    setState({
      ...state,
      [field]: value,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.add_creditcard]: {} }));
    try {
      CreditCardValidator(lang, user.is_admin).validateSync(
        state,
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.creditcards.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.add_creditcard]: errorBag }));
      return false;
    }

    const payload = {
      ...state,
      card_type: getCardType(state.card_number),
    };
    dispatch(postContactCreditCardAction(payload));
    return true;
  };

  return (
    <div style={{ minHeight: '720px' }}>
      <Header height="48px">
        <TopRow>
          <Title>Add a credit card</Title>
          <div style={{ marginLeft: 'auto' }}>
            <CloseButton handleClose={handleClose} />
          </div>
        </TopRow>
      </Header>
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.card_number')}
        name="card_number"
        onChange={onChange}
        required
        value={state.card_number || ''}
        inputProps={{
          type: 'number',
        }} />
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.name')}
        name="name"
        onChange={onChange}
        required
        value={state.name || ''} />
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.first_name')}
        name="first_name"
        onChange={onChange}
        required
        value={state.first_name || ''} />
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.last_name')}
        name="last_name"
        onChange={onChange}
        required
        value={state.last_name || ''} />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
      }}>
        <div>
          <FormTextField
            form={FORM.add_creditcard}
            label={t(lang, 'forms.creditcards.expiration_month')}
            name="expiration_month"
            onChange={onChange}
            required
            value={state.expiration_month || ''}
            inputProps={{
              type: 'number',
            }} />
        </div>
        <div>
          <FormTextField
            form={FORM.add_creditcard}
            label={t(lang, 'forms.creditcards.expiration_year')}
            name="expiration_year"
            onChange={onChange}
            required
            value={state.expiration_year || ''}
            inputProps={{
              type: 'number',
            }} />
        </div>
        <div>
          <FormTextField
            form={FORM.add_creditcard}
            label="cvv"
            name="cvv"
            onChange={onChange}
            value={state.cvv || ''}
            inputProps={{
              type: 'number',
            }} />
        </div>
      </div>
      <BottomRow style={{ alignItems: 'top' }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: 'auto',
            marginRight: '0px',
          }}
          onClick={(e) => handleSubmit(e)}>
          {t(lang, 'misc.save')}
        </Button>
      </BottomRow>
    </div>
  );
};

export default AddCreditCardForm;

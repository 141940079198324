export enum DIALOGS {
  contactDownloadResult = 'contactDownloadResult',
  contactDownloadSelected = 'contactDownloadSelected',
  invoiceDownload = 'invoiceDownload',
  paymentDownload = 'paymentDownload',
  invoiceExport = 'invoiceExport',
  paymentExport = 'paymentExport',
  deleteContact = 'deleteContact',
  deleteCreditCard = 'deleteCreditCard',
  deleteBankAccount = 'deleteBankAccount',
  deleteCustomfield = 'deleteCustomfield',
  deleteContactrelation = 'deleteContactrelation',
  deleteRelation = 'deleteRelation',
  deleteGroup = 'deleteGroup',
  deletePayment = 'deletePayment',
  deletePreset = 'deletePreset',
  deleteInvoice = 'deleteInvoice',
  deleteDepartment = 'deleteDepartment',
  deleteAccount = 'deleteAccount',
  deleteProgram = 'deleteProgram',
  deleteProduct = 'deleteProduct',
  deleteTax = 'deleteTax',
  confirmPaymentModalDialog = 'confirmPaymentModalDialog',
  cantRefundOfflinePayment = 'cantRefundOfflinePayment',
  cantRefundUnpaidPayment = 'cantRefundUnpaidPayment',
  cantCancelOnlinePayment = 'cantCancelOnlinePayment',
  cancelInvoice = 'cancelInvoice',
  refundPayments = 'refundPayments',
  cancelPayments = 'cancelPayments',
  changeEmail = 'changeEmail',
  changePassword = 'changePassword',
  replaceInvoice = 'replaceInvoice',
  deleteEvent = 'deleteEvent',
  leaveTab = 'leaveTab',
  login = 'login',
  deleteAdmin = 'deleteAdmin',
}

export type DialogModel = {
  [dialog in DIALOGS]: {
    opened: boolean
    payload: any,
  }
};

export interface DialogPayload {
  dialog: DIALOGS,
}
